// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bodymake-index-js": () => import("./../../../src/pages/bodymake/index.js" /* webpackChunkName: "component---src-pages-bodymake-index-js" */),
  "component---src-pages-bodymake-microcms-blog-blog-id-js": () => import("./../../../src/pages/bodymake/{microcmsBlog.blogId}.js" /* webpackChunkName: "component---src-pages-bodymake-microcms-blog-blog-id-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-success-js": () => import("./../../../src/pages/contact/success.js" /* webpackChunkName: "component---src-pages-contact-success-js" */),
  "component---src-pages-hair-removal-index-js": () => import("./../../../src/pages/hair-removal/index.js" /* webpackChunkName: "component---src-pages-hair-removal-index-js" */),
  "component---src-pages-hair-removal-microcms-blog-blog-id-js": () => import("./../../../src/pages/hair-removal/{microcmsBlog.blogId}.js" /* webpackChunkName: "component---src-pages-hair-removal-microcms-blog-blog-id-js" */),
  "component---src-pages-haircare-index-js": () => import("./../../../src/pages/haircare/index.js" /* webpackChunkName: "component---src-pages-haircare-index-js" */),
  "component---src-pages-haircare-microcms-blog-blog-id-js": () => import("./../../../src/pages/haircare/{microcmsBlog.blogId}.js" /* webpackChunkName: "component---src-pages-haircare-microcms-blog-blog-id-js" */),
  "component---src-pages-healthcare-index-js": () => import("./../../../src/pages/healthcare/index.js" /* webpackChunkName: "component---src-pages-healthcare-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lifestyle-index-js": () => import("./../../../src/pages/lifestyle/index.js" /* webpackChunkName: "component---src-pages-lifestyle-index-js" */),
  "component---src-pages-lifestyle-microcms-blog-blog-id-js": () => import("./../../../src/pages/lifestyle/{microcmsBlog.blogId}.js" /* webpackChunkName: "component---src-pages-lifestyle-microcms-blog-blog-id-js" */),
  "component---src-pages-mens-cosmetics-index-js": () => import("./../../../src/pages/mens-cosmetics/index.js" /* webpackChunkName: "component---src-pages-mens-cosmetics-index-js" */),
  "component---src-pages-mens-cosmetics-microcms-blog-blog-id-js": () => import("./../../../src/pages/mens-cosmetics/{microcmsBlog.blogId}.js" /* webpackChunkName: "component---src-pages-mens-cosmetics-microcms-blog-blog-id-js" */),
  "component---src-pages-mens-skincare-index-js": () => import("./../../../src/pages/mens-skincare/index.js" /* webpackChunkName: "component---src-pages-mens-skincare-index-js" */),
  "component---src-pages-mens-skincare-microcms-blog-blog-id-js": () => import("./../../../src/pages/mens-skincare/{microcmsBlog.blogId}.js" /* webpackChunkName: "component---src-pages-mens-skincare-microcms-blog-blog-id-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

